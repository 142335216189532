import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute1 = ({ children }) => {
  const isLogin = useSelector((state) => state.isLogin)
  const token = sessionStorage.getItem('token')

  return  token ? <Navigate to="/dashboard" /> : children
}

export default PrivateRoute1

PrivateRoute1.propTypes = {
  children: PropTypes.node.isRequired,
}
