import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useColorModes } from '@coreui/react'
import './scss/style.scss'
import PrivateRoute from './views/pages/privarteroute/PrivateRoute'
import React from 'react'

import PrivateRoute1 from './views/pages/privarteroute/PrivateRoute1'
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))

const App = () => {
  const usertype = useSelector((state) => state.usertype)
  // console.log(usertype);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
  //   const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0];
  //   if (theme) {
  //     setColorMode(theme);
  //   }

  //   if (isColorModeSet()) {
  //     return;
  //   }

  //   setColorMode(storedTheme);

  // }, []);

  return (
    <Routes>
      <Route
        exact
        path="/login"
        name="Login Page"
        element={
          <PrivateRoute1>
            <Login />
          </PrivateRoute1>
        }
      />

      <Route
        path="*"
        name="Home"
        element={
          <PrivateRoute>
            <DefaultLayout />
          </PrivateRoute>
        }
      />


    </Routes>
  )
}

export default App
