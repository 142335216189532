import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const isLogin = useSelector((state) => state.isLogin)
  const token = sessionStorage.getItem('token')

  return token ? children : <Navigate to="/login" />
}

export default PrivateRoute
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}
