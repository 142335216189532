// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@tailwind base;
@tailwind components;
@tailwind utilities;


.nav-item .nav-link {
    color:white;
}

.nav-item .hello {
    color:black;
}

.sidebar-nav svg.nav-icon{
    color: white;
}

.nav-item .dropdown-item{
    cursor: pointer;
}
.nav-item .hii{
    color: red;
}
div .helloo{
    margin-right: 20px !important;
    object-fit: cover !important;
}





`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;AACd,oBAAoB;AACpB,mBAAmB;;;AAGnB;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,UAAU;AACd;AACA;IACI,6BAA6B;IAC7B,4BAA4B;AAChC","sourcesContent":["@tailwind base;\n@tailwind components;\n@tailwind utilities;\n\n\n.nav-item .nav-link {\n    color:white;\n}\n\n.nav-item .hello {\n    color:black;\n}\n\n.sidebar-nav svg.nav-icon{\n    color: white;\n}\n\n.nav-item .dropdown-item{\n    cursor: pointer;\n}\n.nav-item .hii{\n    color: red;\n}\ndiv .helloo{\n    margin-right: 20px !important;\n    object-fit: cover !important;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
